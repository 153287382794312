body {
    margin: 0;
    padding: 0;
    
    /* BACKGROUND WITH BLUR */
    /* background-image: url("http://localhost:3000/assets/bg_1.jpg"); */
    /* BACKGROUND WITH BLUR */


    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}